<script setup>

import { onMounted, ref } from "vue"
import { useCourseStore } from "@/store/courses/course"

const courseStore = useCourseStore()

const courses = ref([])
const loading = ref(true)

const initial = () => {
  loading.value = true

  courseStore.index().then((res) => {
    courses.value = res.data.courses

    loading.value = false
  })
}

onMounted(() => {
	initial()
})

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Courses <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'courses.create' })" /></h5>
        <DataTable 
          class="p-datatable-gridlines" 
          dataKey="id" 
          filterDisplay="row" 
          :lazy="true"
          :loading="loading"
          responsiveLayout="scroll"
          :rowHover="true"
          :value="courses"
        >
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column header="Picture">
            <template #body="{data}">
              <Avatar :image="data.url_picture" size="xlarge" />
            </template>
          </Column>

          <Column field="title" header="Title" />

          <Column field="active" header="Active">
            <template #body="{data}">
              <Chip v-if="data.active" label="Yes" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="No" icon="pi pi-times" class="custom-chip-danger" />
            </template>
          </Column>

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/courses/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>

              <router-link :to="`/courses/${data.id}/edit`">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
              </router-link>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
